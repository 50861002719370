import React, { useEffect, useMemo } from "react";
import { Badge, createTheme, Tab, Tabs, ThemeProvider } from "@mui/material";
import "../App.css";
import { EmailIcon, HomeIcon, SettingsIcon } from "../data/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { AnimatedIconButton } from "./AnimatedIconButton";

export default function Footer() {
    const [value, setValue] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { token, lastNotification } = useUser();

    const tabValues = useMemo(() => {
        return ["/", "/notifications", "/settings"];
    }, []); // 의존성 배열을 비워서 컴포넌트 마운트 시 한 번만 실행

    /**
     * URL 변경 시 제어
     */
    useEffect(() => {
        switch (location.pathname) {
            case tabValues[0]:
                setValue(0);
                break;
            case tabValues[1]:
                setValue(1);
                break;
            case tabValues[2]:
                setValue(2);
                break;
            default:
                setValue(false);
                break;
        }
    }, [location.pathname, tabValues]); // 의존성 배열에 location.pathname 추가

    /**
     * 탭 클릭 시 제어
     */
    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                navigate(tabValues[0]); // 홈으로 이동
                break;
            case 1:
                navigate(tabValues[1]); // 메세지 페이지로 이동
                break;
            case 2:
                navigate(tabValues[2]); // 설정 페이지로 이동
                break;
            default:
                break;
        }
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: "#000000", // Tab 클릭시 검정색으로 설정 (기본값은 파란색)
            },
        },
        typography: {
            fontFamily: ["GangwonEdu_OTFBoldA", "serif"].join(","),
        },
    });

    return (
        token && (
            <ThemeProvider theme={theme}>
                <div className="bottom-tabs">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        centered
                        // 클릭시 아래 작대기 생기는거 없애기
                        TabIndicatorProps={{ style: { background: "transparent" } }}
                    >
                        <Tab icon={<HomeIcon />} label="홈" />
                        <Tab
                            icon={
                                // 마지막 알림 메세지가 읽지 않은 메세지면 N뱃지 노출
                                lastNotification.read === "UNREAD" ? (
                                    <AnimatedIconButton>
                                        <Badge
                                            badgeContent="N"
                                            color="error"
                                            sx={{
                                                "& .MuiBadge-badge": {
                                                    backgroundColor: "red",
                                                    color: "white",
                                                    fontSize: "0.7rem",
                                                    fontWeight: "bold",
                                                    minWidth: "5px",
                                                    height: "17px",
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    transform: "scale(1) translate(-60%, -60%)", // 아이콘 위쪽에 배지 위치 조정
                                                },
                                            }}
                                        >
                                            <EmailIcon />
                                        </Badge>
                                    </AnimatedIconButton>
                                ) : (
                                    <EmailIcon />
                                )
                            }
                            label="알림"
                        />
                        <Tab icon={<SettingsIcon />} label="설정" />
                    </Tabs>
                </div>
            </ThemeProvider>
        )
    );
}
