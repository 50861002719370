// 잔상 효과 애니메이션 정의
import styled from "@emotion/styled";
import { IconButton, keyframes } from "@mui/material";

const pulse = keyframes`
    0% {
        transform: scale(1);
        box-shadow: 0 0 0px rgba(128, 128, 128, 0.7); // 아이콘 크기에서 시작
    }
    70% {
        transform: scale(1.2);
        box-shadow: 0 0 0px rgba(128, 128, 128, 0); // 커지는 효과
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0px rgba(128, 128, 128, 0); // 원래 크기로 돌아옴
    }
`;

// 애니메이션을 적용한 IconButton 스타일링
export const AnimatedIconButton = styled(IconButton)`
    animation: ${pulse} 1.5s infinite;
`;
