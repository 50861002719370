import React, { useEffect } from "react";
import { Box } from "@mui/material";
import TodayFortuneModal from "./TodayFortuneModal";
import WeatherModal from "./WeatherModal";
import NewsModal from "./NewsModal";
import axios from "axios";
import FixedLoading from "../FixedLoading";

export default function ServiceModals({ serviceType, setServiceType, token }) {
    const [todayFortuneModalOpen, setTodayFortuneModalOpen] = React.useState(false);
    const [weatherModalOpen, setWeatherModalOpen] = React.useState(false);
    const [newsModalOpen, setNewsModalOpen] = React.useState(false);
    const [serviceDetailJson, setServiceDetailJson] = React.useState();
    const [serviceModalLoading, setServiceModalLoading] = React.useState(false);

    useEffect(() => {
        if (!serviceType) return;
        setServiceModalLoading(true);
        switch (serviceType) {
            case "TODAY_FORTUNE": {
                axios
                    .get(`/api/fortunes`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        setServiceDetailJson(JSON.stringify(response.data));
                        setTodayFortuneModalOpen(true);
                    })
                    .catch((error) => {})
                    .finally(() => {
                        setServiceModalLoading(false);
                    });
                break;
            }
            case "WEATHER": {
                axios
                    .get(`/api/weathers`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        setServiceDetailJson(JSON.stringify(response.data));
                        setWeatherModalOpen(true);
                    })
                    .catch((error) => {})
                    .finally(() => {
                        setServiceModalLoading(false);
                    });
                break;
            }

            case "NEWS": {
                axios
                    .get(`/api/news`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        setServiceDetailJson(JSON.stringify(response.data));
                        setNewsModalOpen(true);
                    })
                    .catch((error) => {})
                    .finally(() => {
                        setServiceModalLoading(false);
                    });
                break;
            }

            default: {
                setServiceModalLoading(false);
                break;
            }
        }
    }, [serviceType, token]);

    const handleClose = () => {
        setTodayFortuneModalOpen(false);
        setWeatherModalOpen(false);
        setNewsModalOpen(false);
        setServiceType(""); // 모달이 닫힐 때 serviceType을 빈 문자열로 초기화
    };

    return (
        <Box>
            <TodayFortuneModal open={todayFortuneModalOpen} handleClose={handleClose} todayFortuneJson={serviceDetailJson} />
            <WeatherModal open={weatherModalOpen} handleClose={handleClose} weatherJson={serviceDetailJson} />
            <NewsModal open={newsModalOpen} handleClose={handleClose} newsJson={serviceDetailJson} />

            {serviceModalLoading && <FixedLoading />}
        </Box>
    );
}
